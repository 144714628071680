import {WarmVlaanderenConfig} from '../app/website-configuration/warm-vlaanderen-config';
import {Tenant} from '../app/website-configuration/tenant';

export const environment = {
  production: false,
  preview: false,
  tenant: Tenant.WARM_VLAANDEREN,
  apiUrl: 'https://api.acc.warmvlaanderen.net',
  cmsApiUrl: 'https://cms.acc.warmvlaanderen.net/api',
  cmsApiVoorvoegsel: 'wv',
  applicationTitle: 'Warm vlaanderen',
  websiteConfiguratie: WarmVlaanderenConfig,
  matomoSiteId: '6',
  forumUrl: '',
  auth: {
    region: '',
    userPoolId: '',
    userPoolWebClientId: '',
    cognitoDomain: ''
  },
};
